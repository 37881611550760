<template>
  <div class="error-box">
    <div class="py-12">
      <div class="text-center">
        <h1 class="error-title error--text">404</h1>
        <h3 class="text-uppercase error-subtitle">페이지를 찾을 수 없습니다.</h3>
        <br/>
        <b-button
          variant="primary"
          pill
          size="lg"
          href="/starter"
          >홈으로 돌아가기</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  data: () => ({}),
};
</script>

<style lang="scss">
.error-box {
  height: 100%;
  width: 100%;
  position: fixed;
}
.error-title {
  font-size: 210px;
  font-weight: 900;
  text-shadow: 4px 4px 0 #fff, 6px 6px 0 #343a40;
  line-height: 210px;
}

@media (max-width: 991px) {
  .error-title {
    font-size: 120px;
    line-height: 120px;
  }
}

@media (max-width: 767px) {
  .error-title {
    font-size: 40px;
    line-height: 40px;
  }
}
</style>